<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveLocationSubscription"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <section class="section">
        <div class="container">
          <table class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Channel</td>
                <td width="250" class="has-text-right">
                  <div class="select">
                    <select v-model="newSubscription.ChannelId">
                      <option :value="0" :selected="channelId === 0"
                        >All channels</option
                      >
                      <option
                        v-for="channel in locationState.Channels"
                        :key="channel.ChannelId"
                        :value="channel.ChannelId"
                        :selected="channelId === channel.ChannelId"
                        >{{ channel.ChannelName }}</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="newSubscription.ChannelId > 0">
                <td>Package</td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      @change="setPackage"
                      v-model="newSubscription.PackageId"
                    >
                      <option
                        value="0"
                        :selected="newSubscription.PackageId === 0"
                        >Select a package</option
                      >
                      <option
                        v-for="p in pckgs"
                        :key="p.Id"
                        :value="p.Id"
                        :selected="newSubscription.PackageId === p.Id"
                        >{{ p.Name }}</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="newSubscription.PackageId > 0">
                <td>Period</td>
                <td class="has-text-right">
                  <div class="select">
                    <select v-model="month">
                      <option :value="1" :selected="month === 1"
                        >Januari</option
                      >
                      <option :value="2" :selected="month === 2"
                        >Februari</option
                      >
                      <option :value="3" :selected="month === 3">March</option>
                      <option :value="4" :selected="month === 4">April</option>
                      <option :value="5" :selected="month === 5">May</option>
                      <option :value="6" :selected="month === 6">June</option>
                      <option :value="7" :selected="month === 7">July</option>
                      <option :value="8" :selected="month === 8">August</option>
                      <option :value="9" :selected="month === 9"
                        >September</option
                      >
                      <option :value="10" :selected="month === 10"
                        >October</option
                      >
                      <option :value="11" :selected="month === 11"
                        >November</option
                      >
                      <option :value="12" :selected="month === 12"
                        >December</option
                      >
                    </select>
                  </div>

                  <div class="select">
                    <select v-model="year">
                      <option
                        v-for="y in years"
                        :key="y"
                        :value="y"
                        :selected="year === y"
                        >{{ y }}</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr v-if="newSubscription.PackageId > 0">
                <td>Monthly fixed fee</td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="newSubscription.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="newSubscription.Price"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section
        v-if="hasMeetingspace && newSubscription.PackageId > 0"
        class="section"
      >
        <div class="container">
          <table class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Meetingspace fee</td>
                <td width="250" class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="newSubscription.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="newSubscription.SeatFeeMS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Meetingspace max. fee seats</td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="newSubscription.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="newSubscription.MaxSeatFeeTotalMS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section
        v-if="hasWorkspace && newSubscription.PackageId > 0"
        class="section"
      >
        <div class="container">
          <table class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Workspace fee</td>
                <td width="250" class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="newSubscription.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="newSubscription.SeatFeeWS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Workspace max. fee seats</td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="newSubscription.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="newSubscription.MaxSeatFeeTotalWS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section v-if="newSubscription.PackageId > 0" class="section">
        <div class="container">
          <table class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Billable</td>
                <td width="250" class="has-text-right">
                  <input
                    type="checkbox"
                    v-model="newSubscription.SendInvoice"
                    id="freeTrial"
                    class="checkbox"
                  />
                </td>
              </tr>
              <tr>
                <td>Free trial</td>
                <td class="has-text-right">
                  <input
                    type="checkbox"
                    v-model="newSubscription.IsTrial"
                    id="freeTrial"
                    class="checkbox"
                  />
                </td>
              </tr>
              <tr>
                <td>Recurring</td>
                <td class="has-text-right">
                  <input
                    type="checkbox"
                    v-model="newSubscription.IsRecurring"
                    id="freeTrial"
                    class="checkbox"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import subscriptionProvider from '@/providers/subscription'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      month: new Date().getMonth() + 1,
      newSubscription: null,
      year: new Date().getFullYear(),
      years: [],
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('packageStore', ['pckgs']),

    hasMeetingspace() {
      let result = false

      if (this.locationState) {
        result =
          this.locationState.Meetingtypes.findIndex(
            (m) => m.MeetingtypeId === 1
          ) > -1
      }

      return result
    },

    hasWorkspace() {
      let result = false

      if (this.locationState) {
        result =
          this.locationState.Meetingtypes.findIndex(
            (m) => m.MeetingtypeId === 2
          ) > -1
      }

      return result
    }
  },

  created() {
    this.setupNewSubscription()

    let currentYear = new Date().getFullYear()
    let minYear = currentYear - 2
    let maxYear = currentYear + 1

    while (minYear <= maxYear) {
      this.years.push(minYear)
      minYear++
    }

    this.getAllPackages(this.channelId)
  },

  methods: {
    ...mapActions('packageStore', ['getAllPackages']),

    setPackage() {
      let selectedPackage = this.pckgs.find(
        (p) => p.Id === this.newSubscription.PackageId
      )

      if (selectedPackage) {
        this.newSubscription.CurrencyId = selectedPackage.CurrencyId
        this.newSubscription.CurrencySymbol = selectedPackage.CurrencySymbol
        this.newSubscription.Price = selectedPackage.MonthFee
        this.newSubscription.SeatFeeMS = selectedPackage.MsSeatFee
        this.newSubscription.SeatFeeWS = selectedPackage.WsSeatFee
        this.newSubscription.MaxSeatFeeTotalMS = 0
        this.newSubscription.MaxSeatFeeTotalWS = 0
        this.newSubscription.NrOfFreeSeats = 0
      }
    },

    setupNewSubscription() {
      this.newSubscription = {
        LocationId: this.locationState.Id,
        ChannelId: this.channelId,
        PackageId: 0,
        CurrencyId: 0,
        Price: 0,
        SeatFeeMS: 0,
        SeatFeeWS: 0,
        MaxSeatFeeTotalMS: 0,
        MaxSeatFeeTotalWS: 0,
        NrOfFreeSeats: 0,
        IsMonthly: true,
        IsYearly: false,
        IsTrial: false,
        IsPaid: false,
        IsRecurring: true,
        IsCancelled: false,
        SendInvoice: true,
      }
    },

    saveLocationSubscription() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        self.newSubscription.StartDate = self.year + '-' + self.month + '-01'

        subscriptionProvider.methods
          .addSubscription(this.newSubscription)
          .then((response) => {
            if (response.status === 200) {
              self.$emit('subscriptionAdded', response.data)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Subscription added!',
              })

              self.onClickCancel()
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })

        // subscriptionProvider.methods
        //   .addSubscription(self.newSubscription)
        //   .then((response) => {
        //     if (response.status === 200) {
        //       self.isSavingSuccess = true

        //       setTimeout(() => {
        //         self.onClickCancel()
        //       }, 1500)
        //     }
        //   })
        //   .catch((e) => {
        //     self.isSavingError = true
        //   })
        //   .finally(() => {
        //     self.isSaving = false
        //   })
      }
    },
  },
}
</script>
